import EntityForm from "@/mixins/EntityForm";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";
import { EventBus } from "@/lib/EventBus";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    groupService: "groupService",
    visitorPermissionService: "visitorPermissionService",
    visitorLevelService: "visitorLevelService",
    customFieldService: "customFieldService",
    toastService: "toastService"
  },
})
export default class UserForm extends mixins(EntityForm, WithVisitorLevelPermissions) {
  model = {};
  platformLanguages = [];
  platformGroups = [];
  customFields = [];
  autologinDialog = false;
  verificationTokenDialog = false;
  agendaLinkDialog = false;

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "language",
            label: this.translations.labels.user_form_languages,
            type: "select",
            required: true,
            returnObject: false,
            items: this.languagesOption
          },
          {
            id: "idGroupList",
            label: this.translations.labels.user_form_groups,
            type: "select",
            multiple: true,
            required: true,
            returnObject: false,
            items: this.groupsOption
          },
          {
            id: "firstName",
            label: this.translations.labels.user_form_firstName,
            type: "text",
            required: true
          },
          {
            id: "lastName",
            label: this.translations.labels.user_form_lastName,
            type: "text",
            required: true
          },
          {
            id: "email",
            label: this.translations.labels.user_form_email,
            type: "text",
            required: true,
            rules: "emailFormat"
          },
          {
            id: "username",
            label: this.translations.labels.user_form_username,
            type: "text",
            required: true
          },
          {
            id: "expirationDate",
            label: this.translations.labels.user_form_expirationDate,
            type: "datetime",
            required: false,
          },
          {
            id: "isVerified",
            label: this.translations.labels.user_form_isVerified,
            type: "checkbox",
            required: false,
            disabled: true
          },
          {
            id: "verificationToken",
            label: this.translations.labels.user_form_verificationToken,
            type: "text",
            required: false,
            disabled: true,
            resetColumns: true,
            sideIcon: "mdi-pencil",
            sideAction: () => {
              this.verificationTokenDialog=true;
            },
          },
          {
            id: "autoLoginCode",
            label: this.translations.labels.user_form_autologinCode,
            type: "text",
            required: false,
            disabled: true,
            resetColumns: true,
            sideIcon: "mdi-pencil",
            sideAction: () => {
              this.autologinDialog = true;
            },
          },

          // custom fields
          {
            id: "userCustomFields",
            type: "elements",
            group: "object",
            noPadding: true,
            collapsible: true,
            open: false,
            resetColumns: true,
            elements: [
              {
                text: this.translations.labels.userCustomField_form_userCustomFields,
                type: "divider",
                resetColumns: true
              },
              ...this.userCustomFields(),
            ]
          },
          // end custom fields
          // old fields
          {
            text: this.translations.labels.user_form_userInformationFieldsDivider,
            type: "divider",
            resetColumns: true
          },
          {
            id: "birthDate",
            label: this.translations.labels.user_form_birthdate,
            type: "datetime",
            required: false
          },
          {
            id: "mobilePhone",
            label: this.translations.labels.user_form_mobilePhone,
            type: "text",
            required: false
          },
          {
            id: "telephone",
            label: this.translations.labels.user_form_phone,
            type: "text",
            required: false
          },
          {
            id: "company",
            label: this.translations.labels.user_form_company,
            type: "text",
            required: false
          },
          {
            id: "profession",
            label: this.translations.labels.user_form_profession,
            type: "text",
            required: false
          },
          {
            id: "country",
            label: this.translations.labels.user_form_country,
            type: "text",
            required: false
          },
          {
            id: "province",
            label: this.translations.labels.user_form_province,
            type: "text",
            required: false
          },
          {
            id: "city",
            label: this.translations.labels.user_form_city,
            type: "text",
            required: false
          },
          {
            id: "address",
            label: this.translations.labels.user_form_address,
            type: "text",
            required: false
          },
          {
            id: "zipCode",
            label: this.translations.labels.user_form_zipCode,
            type: "text",
            required: false
          },
          {
            id: "businessCard",
            label: this.translations.labels.user_form_businessCard,
            type: "text",
            required: false
          },
          {
            id: "publicBusinessData",
            label: this.translations.labels.user_form_publicBusinessData,
            type: "checkbox",
            required: false
          },
          // end old fields
        ]
      },
      {
        id: "visitorLevels",
        type: "visitor-levels-permissions",
        resetColumns: true,
        transcludeModel: true,
        legend: this.translations.labels.visitor_level,
        label: this.translations.labels.visitor_level,
        levels: this.currentVisitorLevelsOptions,
      },
    ];
  }

  get languagesOption() {
    return this.platformLanguages.map((l) => {
      return {
        value: { idLanguage: l.id, cultureCode: l.code },
        label: l.description,
      };
    });
  }

  get groupsOption() {
    return this.platformGroups.map((g) => {
      return {
        value: g.id,
        label: g.description,
      };
    });
  }

  get helpLink() {
    return this.translations.pageHelp.user;
  }

  agendaLinkField () {
    return {
      id: "agendaLink",
      type: "button",
      leftIcon: "mdi-calendar",
      text: this.translations.labels.common_form_agenda_link,
      label: this.translations.labels.common_form_agenda_link,
      resetColumns: true,
      hide: () => {
        return this.model.groups.filter(group => group.name === "Speaker").length == 0;
      },
      handler: () => {
        this.agendaLinkDialog = true;
      },
    };
  }

  async loadAttendances() {
    this.model.attendances = (await this.visitorPermissionService.attendances(this.model.id)).map(a => {
      return {
        id: a.id,
        title: a.code,
        description: `${a.entityType}/${a.idEntity}`,
        group: a.entityType,
        idEntity: a.idEntity,
        entityType: a.entityType,
        idUser: this.model.id
      };
    });
  }

  buildCustomField(customField) {
    let hint = [];
    if (customField.isHiddenToUser) {
      hint.push(this.translations.labels.userCustomField_form_hiddenToUser);
    }
    if (customField.userCustomField_form_nonEditableByUser) {
      hint.push(this.translations.labels.userCustomField_form_nonEditableByUser);
    }

    let fieldOptions;
    try {
      fieldOptions = JSON.parse(customField.fieldOptions);
    } catch (_e) {
      fieldOptions = {};
    }

    let customFieldDef = {
      id: customField.id,
      label: customField.code,
      required: customField.required,
      hint: hint.join("; "),
      type: "text" // default it with text; we will change it in the following switch case
    };

    const fieldType = customField.fieldType.toLowerCase();
    switch (fieldType) {
      case "select":
        customFieldDef.type = fieldType;
        customFieldDef.items = (fieldOptions?.values ?? []).map(v => {
          return {
            value: v,
            label: v
          };
        });
        break;
      case "email":
      case "datetime":
        customFieldDef.type = fieldType;
        break;
      case "number":
      case "text":
        customFieldDef.type = fieldType;
        customFieldDef = {
          ...fieldOptions,
          ...customFieldDef
        };
        break;
    }

    return customFieldDef;
  }

  userCustomFields() {
    let customFieldsDef = [];
    for (const i in this.customFields) {
      const customField = this.customFields[i];
      if (!customField.isEnabled) {
        continue;
      }
      customFieldsDef.push(this.buildCustomField(customField));
    }
    return customFieldsDef;
  }

  resetPasswordField() {
    const resetUserPasswordsDialog = {
      header: this.translations.labels.user_form_reset_user_password,
      buttonText: this.translations.labels.user_form_reset_user_password,
      type: "dialog",
      resetColumns: true,
      withCloseBtn: true,
      elements: [
        {
          id: "resetPasswordValue",
          type: "text",
          resetColumns: true,
          required: true,
          label: this.translations.labels.user_form_resetPasswordValue,
          hint: this.translations.labels.user_form_resetUserPassword_hint,
          rightIcon: "mdi-content-save-outline",
          onClickRightIcon: async () => {
            const confirm = window.confirm(this.translations.labels.user_form_resetUserPasswordConfirm);
            if (confirm) {
              try {
                await this.userService.resetUserPassword(
                  this.model.id,
                  {
                    password: this.model.resetPasswordValue
                  }
                );
                this.toastService.success(
                  this.translations.success.password_resetted
                );
              } catch (error) {
                this.toastService.error(
                  this.translations.errors.somethingWrong
                );
              }
            }
          },
        }
      ],
    };
    return resetUserPasswordsDialog;
  }

  attendancesField(readonly) {
    const attendandesDialog = {
      header: this.translations.labels.user_form_attendances,
      buttonText: this.translations.labels.user_form_attendances,
      type: "dialog",
      resetColumns: true,
      withCloseBtn: true,
      elements: [
        {
          id: "attendances",
          type: "datalist",
          searchable: true,
          resetColumns: true,
          emptyList: this.translations.labels.list_empty,
          readonly: readonly,
          actions: [
            {
              icon: "mdi-delete",
              tooltip: this.translations.labels.user_form_revokeAttendance,
              hide: (_action, structure) => {
                return structure.readonly;
              },
              handler: async (item, _structure, refreshList) => {
                const confirm = window.confirm(this.translations.labels.user_form_revokeAttendanceConfirm);
                if (confirm) {
                  await this.visitorPermissionService.revoke(item.id);
                  await this.loadAttendances();
                  refreshList();
                }
              }
            }
          ]
        }
      ]
    };
    if (!readonly) {
      attendandesDialog.elements.push({
        id: "newAttendance",
        type: "text",
        resetColumns: true,
        label: this.translations.labels.user_form_newAttendance,
        hint: this.translations.labels.user_form_newAttendance_hint,
        autocomplete: "off",
        rightIcon: "mdi-plus-circle",
        onClickRightIcon: async (_value, field) => {
          const newAttendance = this.model.newAttendance;
          if (!newAttendance) {
            return;
          }
          try {
            await this.visitorPermissionService.permit(newAttendance, this.model.id);
            this.model.attendances = [];
            await this.loadAttendances();
            EventBus.$emit("refresh:edit-user-form/attendances");
            this.model.newAttendance = "";
          } catch (err) {
            if (err.description === "InvitationDoesNotExist" || err.description === "VisitorPermissionDuplicated") {
              const errMsg = err.description === "InvitationDoesNotExist"
                ? this.translations.errors.invitationNotFound
                : this.translations.errors.visitorPermissionDuplicated;
              this.toastService.error(errMsg);
              EventBus.$emit(
                "error:" + field.ancestor,
                field.hierarchy,
                errMsg
              );
            } else {
              throw (err);
            }
          }
        }
      });
    }
    return attendandesDialog;
  }

  prepareCustomFieldsModel(customFields) {
    let customFieldsModel = {};
    for (const i in customFields) {
      const customField = this.model.customFields[i];
      customFieldsModel[customField.idCustomField] = customField.value;
    }
    return customFieldsModel;
  }

  formatCustomFieldsToSave(userCustomFields) {
    let formattedCustomFields = [];
    for (let i in userCustomFields) {
      formattedCustomFields.push({
        id: i,
        value: userCustomFields[i]
      });
    }
    return formattedCustomFields;
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {
  }

  async created() {
    this.customFields = await this.customFieldService.list();
    this.platformLanguages = await this.languageService.list();
    this.platformGroups = await this.groupService.list();

    this.afterCreate();
  }
}
