<template>
  <div class="entity-form">
    <div class="entity-form__body" v-if="isReady">
      <v-dialog v-model="autologinDialog" width="1000">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ translations.labels.autologin_event_dialog }}
          </v-card-title>
          <v-row class="mx-auto">
            <v-col cols="8">
              <v-select
                v-model="selectedEventAutoLogin"
                name="idEvent"
                label="Event"
                :hind="translations.labels.autologin_event_dialog_hint"
                persistent-hint
                :items="events"
                item-text="label"
                item-value="value"
                return-object
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div @click="copyAutoLoginToClipboard" class="copyable-text">
                <v-text-field
                  v-model.trim="autoLoginURL"
                  name="autoLoginURL"
                  :label="translations.labels.user_form_autologinURL"
                  disabled
                  type="text"
                  outlined
                />
              </div>
              <div v-if="feedbackIsVisible" class="feedback">Copied!</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeAutoLoginEventDialog">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="verificationTokenDialog" width="1000">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ translations.labels.verificationToken_event_dialog }}
          </v-card-title>
          <v-row class="mx-auto">
            <v-col cols="8">
              <v-select
                v-model="selectedEventVerificationToken"
                name="idEvent"
                label="Event"
                :hint="translations.labels.verificationToken_event_dialog_hint"
                persistent-hint
                :items="events"
                item-text="label"
                item-value="value"
                return-object
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="copyable-text-wrapper">
                <div
                  @click="copyVerificationTokenToClipboard"
                  class="copyable-text wrapper-field"
                >
                  <v-text-field
                    v-model.trim="verificationTokenURL"
                    name="verificationTokenURL"
                    :label="translations.labels.user_form_verificationTokenUrl"
                    disabled
                    type="text"
                    outlined
                  />
                </div>
              </div>
              <div v-show="feedbackIsVisible" class="feedback">Copied!</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="closeVerificationTokenEventDialog"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="agendaLinkDialog" width="1000">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ translations.labels.agenda_link_dialog }}
          </v-card-title>
          <v-row class="mx-auto">
            <v-col cols="8">
              <v-select
                v-model="selectedEventAgendaLink"
                name="idEvent"
                label="Event"
                :hint="translations.labels.agenda_link_dialog_hint"
                persistent-hint
                :items="events"
                item-text="label"
                item-value="value"
                return-object
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div @click="copyAgendaLinkToClipboard" class="copyable-text">
                <v-text-field
                  v-model.trim="agendaLinkUrl"
                  name="agendaLinkUrl"
                  :label="translations.labels.common_form_agenda_link"
                  disabled
                  type="text"
                  outlined
                />
              </div>
              <div v-if="feedbackIsVisible" class="feedback">Copied!</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeAgendaLinkDialog">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <FormBody
        :form-id="getFormId()"
        :form-title="getFormTitle()"
        :model.sync="model"
        :actions="actions"
        :elements="decoratedElements"
        :columns="fieldColumns"
        :errors.sync="errors"
        :helpLink="helpLink"
        v-on="actionsHandlers"
      >
        <slot></slot>
      </FormBody>
    </div>
  </div>
</template>
<script>
import UserForm from "@/views/menu/user/userForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";
import _env from "@/plugins/env";

@Component({
  inject: {
    userService: "userService",
    eventService: "eventService",
    visitorLevelService: "visitorLevelService",
    platformconfigurationService: "platformConfigurationService",
  },
})
export default class UserDetail extends mixins(UserForm) {
  formId = "detail-user-form";
  feedbackIsVisible = false;
  internal_events = [];
  selectedEventAutoLogin = {};
  selectedEventVerificationToken = {};
  selectedEventAgendaLink = {};
  mainEventCode = "";

  get autoLoginURL() {
    let eventCode = this.mainEventCode;

    if (
      this.autoLoginEventCode !== undefined &&
      this.autoLoginEventCode !== null
    ) {
      eventCode = this.autoLoginEventCode;
    }

    return this.model.autoLoginCode
      ? `${_env("VUE_APP_VIRTUAL_PLACE_URL")}/event-${eventCode}?autologinCode=${this.model.autoLoginCode}`
      : "";
  }

  get verificationTokenURL() {
    let eventCode = this.mainEventCode;

    if (
      this.verificationTokenEventCode !== undefined &&
      this.verificationTokenEventCode !== null
    ) {
      eventCode = this.verificationTokenEventCode;
    }

    return this.model.verificationToken
      ? `${_env("VUE_APP_VIRTUAL_PLACE_URL")}/event-${eventCode}/auth/completeRegistration?verificationToken=${this.model.verificationToken}&passwordUpdate=true`
      : "";
  }

  get agendaLinkUrl() {
    let eventCode = this.mainEventCode;

    if (
      this.agendaLinkEventCode !== undefined &&
      this.agendaLinkEventCode !== null
    ) {
      eventCode = this.agendaLinkEventCode;
    }

    return Utility.generateEntityLink({ code: eventCode }, "agenda", null, { speaker: this.model.oid });
  }

  closeAutoLoginEventDialog() {
    this.autologinDialog = false;
  }

  closeVerificationTokenEventDialog() {
    this.verificationTokenDialog = false;
  }

  closeAgendaLinkDialog() {
    this.agendaLinkDialog = false;
  }

  showFeedBack() {
    this.feedbackIsVisible = true;

    setTimeout(() => {
      this.feedbackIsVisible = false;
    }, 2000);
  }
  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }
  get eventCode() {
    return this.selectedEvent?.label;
  }
  get decoratedElements() {
    return this.readonlyFields(
      this.prepend(
        this.prepend(
          this.elements,
          this.agendaLinkField()
        ),
        this.attendancesField(true)
      )
    );
  }

  copyAutoLoginToClipboard() {
    navigator.clipboard.writeText(this.autoLoginURL);

    this.showFeedBack();
  }

  copyVerificationTokenToClipboard() {
    navigator.clipboard.writeText(this.verificationTokenURL);

    this.showFeedBack();
  }

  copyAgendaLinkToClipboard() {
    navigator.clipboard.writeText(this.agendaLinkUrl);

    this.showFeedBack();
  }

  get fieldColumns() {
    return 3;
  }

  getFormTitle() {
    return this.translations.pageTitles.users_detail;
  }

  get autoLoginEventCode() {
    return this.selectedEventAutoLogin?.label;
  }

  get verificationTokenEventCode() {
    return this.selectedEventVerificationToken?.label;
  }

  get agendaLinkEventCode() {
    return this.selectedEventAgendaLink?.label;
  }

  async afterCreate() {
    this.model = await this.userService.read(this.$route.params.id);

    this.internal_events = await this.eventService.list();
    this.model.language = {
      idLanguage: this.model.idLanguage,
      cultureCode: this.model.cultureCode,
    };
    this.model.userCustomFields = this.prepareCustomFieldsModel(
      this.model.customFields
    );

    this.model.idGroupList = this.model.groups.map((g) => g.id);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledUserLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    await this.loadAttendances();
    this.isReady = true;

    this.model.agendaLink = Utility.generateEntityLink(this?.event, "agenda", null, { parent: this.model.id });

    this.mainEventCode = await this.platformconfigurationService.mainEventCode();
  }
}
</script>
<style lang="scss" scoped>
.icon-wrapper {
  cursor: pointer;
}

.copyable-text {
  cursor: pointer;
}

.feedback {
  background-color: #8bd499;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
}
</style>